import React from 'react';
import styled from 'styled-components';

// ${p => p.shouldAnimate && css`
//     transition: transform 0.3s ease-out;
//     &:hover {
//       transform: scale(1.3);
//     }
//   `}

const AnimatingButton = styled.button`
  transition: transform 0.2s ease-out;
  &:hover {
    transform: scale(1.2);
  }
`;

export default ({
  svg, children, className, style, onClick,
}) => (
  <AnimatingButton className={className} style={style} onClick={onClick}>
    {svg || children}
  </AnimatingButton>
);
