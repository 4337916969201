import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';

import WideCard from '../components/ui/wideCard';
import WideGrid from '../components/ui/wideGrid';
import PageHeader from '../components/ui/pageHeader';

const SubCategoriesPage = ({ pageContext }) => {
  const { categoryName, subCategories: subCategoriesFromCms } = pageContext;

  const subCategories = subCategoriesFromCms.map(cat => ({
    ...cat,
    link: `/products/${cat.id}`,
  }));

  return (
    <Layout noMainMarginsMobile>
      <SEO title={categoryName} />

      <PageHeader>
        {categoryName}
      </PageHeader>

      <WideGrid>
        {subCategories.map(subCat => (
          <WideCard
            key={subCat.name}
            title={subCat.name}
            subTitle={subCat.description}
            imageUrl={subCat.imageUrl}
            link={subCat.link}
          >
            {subCat.name}
          </WideCard>
        ))}
      </WideGrid>
    </Layout>
  );
};

export default SubCategoriesPage;
