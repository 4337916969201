/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';

import useShouldAnimateOnHover from '../../hooks/useShouldAnimateOnHover';

import PageTitle from './pageTitle';
import PageTransitionLink from './pageTransitionLink';

import AngleLeftImage from '../../../assets/svg/angle-left.svg';
import AnimatingSvgButton from './animatingSvgButton';

const Styles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: ${p => p.theme.breakpoints.xxl}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media(max-width: ${p => p.theme.breakpoints.s}) {
    padding: ${p => p.theme.layout.contentPaddingMobile};
    padding-bottom: 0;
  }
`;

const TitleArea = styled.div`
  display: flex;
  align-items: center;

  h1, h2, h3 {
    margin-left: 0.4rem;
  }
`;

const BackButton = styled(AnimatingSvgButton)`
  height: 3rem;
  cursor: pointer;

  margin-right: 0.1rem;
  
  svg, img {
    height: 100%;
  }

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    height: 2rem;
  }
`;

export default ({
  children, className, style, noBackButton,
}) => {
  const shouldAnimate = useShouldAnimateOnHover();

  return (
    <Styles className={className} style={style}>
      <TitleArea>
        {!noBackButton
        && (
          <PageTransitionLink to="/categories">
            <BackButton
              shouldAnimate={shouldAnimate}
            >
              <AngleLeftImage />
            </BackButton>
          </PageTransitionLink>
        )}

        <PageTitle>{children}</PageTitle>
      </TitleArea>
    </Styles>
  );
};
