import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;

  margin: 2rem 0;

  &>* {
    margin: 1em;
  }

  @media(max-width: ${p => p.theme.breakpoints.xxl}) {
    grid-template-columns: 50% 50%;
  }

  @media(max-width: ${p => p.theme.breakpoints.xl}) {
    grid-template-columns: 100%;
  }

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    &>* {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

export default ({ children }) => <Styles>{children}</Styles>;
