import React from 'react';
import styled, { css } from 'styled-components';
import useShouldAnimateOnHover from '../../hooks/useShouldAnimateOnHover';

import Overlay from './overlay';
import PageTransitionLink from './pageTransitionLink';

const Styles = styled.div`
  position: relative;

  width: 100%;
  height: 55vh;
  
  background-color: black;

  overflow: hidden;

  ${p => p.link && css`cursor: pointer;`}
  
  ${p => p.shouldAnimateOnHover && css`    
    h1 {
      transition: transform 300ms ease-out;
      transform-origin: bottom left;
    }

    &:hover {
      h1 {
        transform: scale(1.06);
      }
    }
  `}
`;

const TextArea = styled.div`
  position: relative;
  z-index: 2;
  margin: 2rem 3rem;

  @media(max-width: ${p => p.theme.breakpoints.s}) {
    margin: 1rem 1rem;
  }
`;

const Title = styled.h1`
  color: ${p => p.theme.colors.background};

  font-weight: ${p => p.theme.fonts.weights.black};
  font-size: 3rem;

  text-shadow: ${p => p.theme.shadow.textShadow};

  @media(max-width: ${p => p.theme.breakpoints.m}) {
    font-size: 2rem;
  }
`;

const SubTitle = styled.h2`
  color: ${p => p.theme.colors.background};

  font-weight: ${p => p.theme.fonts.weights.regular};
  font-size: 2rem;

  text-shadow: ${p => p.theme.shadow.textShadow};
  
  margin-top: 1rem;

  @media(max-width: ${p => p.theme.breakpoints.s}) {
    font-size: 1.4rem;
  }
`;

const ImageArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    top: 50%;
    left: 50%;

    width: auto;
    height: auto;

    max-height: none;
    max-width: none;

    min-height: 100%;
    min-width: 100%;

    transform: translate(-50%, -50%);
  }
`;

export default ({
  title, subTitle, imageUrl, link,
}) => {
  const shouldAnimateOnHover = useShouldAnimateOnHover();

  const Content = () => (
    <Styles link={link} shouldAnimateOnHover={shouldAnimateOnHover}>
      {imageUrl && (
      <ImageArea>
        <Overlay />
        <img src={imageUrl} alt={title} />
      </ImageArea>
      )}

      <TextArea>
        <Title>{title}</Title>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </TextArea>
    </Styles>
  );

  return link ? <PageTransitionLink to={link}><Content /></PageTransitionLink> : <Content />;
};
