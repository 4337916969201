import styled, { css } from 'styled-components';

export default styled.h1`
  font-weight: ${p => p.theme.fonts.weights.black};
  font-size: 3rem;
  ${p => p.subTitle && css`
    font-size: 2rem;
    margin-top: 0.6rem;
  `}

  ${p => p.center && css`
    text-align: center;
  `}

  @media(max-width: ${p => p.theme.breakpoints.l}) {
    font-size: 2rem;
    ${p => p.subTitle && css`font-size: 1.5rem;`}
  }
`;
